import Model from './Model.js';


class ServiceRequestEquipmentDetailCreateDTO extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('equipmentId', String, true);
        this.prop('loaded', Boolean, false);
        this.prop('hazardous', Boolean, false);
        this.prop('forceEstimate', Boolean, false);
        this.prop('driverPresent', Boolean, false);
        this.prop('contactDriver', Boolean, true);
        this.prop('driverId', Number, true);
        this.prop('driverName', String, true);
        this.prop('driverPhoneNumber', String, true);

        this.populate(values, config);

    }
    static name = 'ServiceRequestEquipmentDetailCreateDTO';
}
window[Symbol.for(ServiceRequestEquipmentDetailCreateDTO.name)] = ServiceRequestEquipmentDetailCreateDTO;

export default ServiceRequestEquipmentDetailCreateDTO;