<template>
    <div class="build-select">
        <transition-group name="cross-fade">
            <div v-for="value in modelValue" :key="value" @click="select(value)" class="value">{{options[value]}}</div>
            <CustomSelect 
                v-model="nextValue" 
                :options="filteredOptions" 
                :placeholder="modelValue.length ? `Select another ${name}`: `Select ${name}`"
                :name="name"
                @update:modelValue="select"
                key="input"
            ></CustomSelect>
        </transition-group>
    </div>
    
</template>
<script>
import CustomSelect from "@/components/inputs/CustomSelect";
import {computed, ref} from "@vue/runtime-core";
export default {
    components: {CustomSelect},
    props: ['modelValue', 'options', 'placeholder', 'name'],
    setup: (props, { emit }) => {
        {
            const nextValue = ref('');
            
            const filteredOptions = computed(() => {
               let build = {...props.options};
               let values = Object.values(props.modelValue);
               for (let i in build){
                   if (values.includes(i)){
                       delete build[i];
                   }
               }
               return build;
            });

            const select = function(value){
                const i = props.modelValue.indexOf(value);
                let newValue = props.modelValue.slice();
                if(i !== -1){
                    newValue.splice(i, 1);
                    emit("update:modelValue", newValue);
                } else {
                    newValue.push(value);
                    emit("update:modelValue", newValue);
                }
            };
            
            return {
                nextValue,
                filteredOptions,

                select,
                
            }
        }
    },
    emits: ['update:modelValue']
}
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";
.build-select{
    .value{
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        margin-bottom: $space-standard;
        background: $white;
        width: 100%;
        
        &::after{
            content: '\f00d';
            display: block;
            float: right;
            line-height: 40px;
            width: 30px;
            font-family: $font-fa;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            color: rgba($font-color, 0);
        }
        
        &:hover{
            background: nth($brands, 10);
            
            &::after{
                color: rgba($red, 1);
            }
        }
    }
}

</style>