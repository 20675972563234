<template>
    <transition name="overlay" appear>
        <div class="overlay" @click="close">
            <div class="overlay-wrapper" @click.stop>
                <!-- <a @click.prevent="close" class="overlay-close"><i class="fa-regular fa-circle-xmark"></i></a> -->
                <div>
                    <slot name="content"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

    export default {
        props: [
        ],
        emits: [
            'close'
        ],
        computed: {
        },
        methods: {
            close(){
                this.$emit("close");
            }
        },
        mounted: function(){   
            document.body.style.overflow = 'hidden';
        },
        beforeUnmount: function(){
            document.body.style.overflow = 'auto';
        }
    }
</script>

<style lang="scss">

@import "../../assets/scss/variables.scss";
.overlay{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 110;
    background: rgba(0,0,0,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: $transition-standard;

    .overlay-wrapper{
        border-radius: 5px;
        position: relative;
        background: #FFFFFF;
        min-width: 400px;
        padding: $space-wide * 2;
        box-shadow: 0 1px 3px rgba(0,0,0,0.5);
    }
    .overlay-close{
        position: absolute;
        top: $space-standard;
        right: $space-standard;
    }

}

.overlay-enter-from, .overlay-leave-to{
    opacity: 0;
}
</style>