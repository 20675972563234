import Model from './Model.js';

import EquipmentCreateDTO from './EquipmentCreateDTO.js';
import ServiceLocationType from './ServiceLocationType.js';
import ServiceLocationCreateDTO from './ServiceLocationCreateDTO.js';
import PreferredContactMethodType from './PreferredContactMethodType.js';
import ServiceRequestEquipmentDetailCreateDTO from './ServiceRequestEquipmentDetailCreateDTO.js';
import ServiceRequestIssueCreateDTO from './ServiceRequestIssueCreateDTO.js';

class ServiceRequestCreateDTO extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('equipment', [Number], true);
        this.prop('newEquipment', [EquipmentCreateDTO], true);
        this.prop('requestorId', Number, true);
        this.prop('clientAccountId', Number, true);
        this.prop('serviceLocationType', ServiceLocationType, false);
        this.prop('requestTowing', Boolean, false);
        this.prop('shopLocationId', Number, true);
        this.prop('serviceLocationId', Number, true);
        this.prop('newServiceLocation', ServiceLocationCreateDTO, false);
        this.prop('urgentRequest', Boolean, false);
        this.prop('availableImmediately', Boolean, true);
        this.prop('startTime', Date, true);
        this.prop('endTime', Date, true);
        this.prop('primaryContactId', Number, true);
        this.prop('primaryContactName', String, true);
        this.prop('primaryContactEmail', String, true);
        this.prop('primaryContactPhone', String, true);
        this.prop('primaryPreferredContactMethod', PreferredContactMethodType, false);
        this.prop('isSecondaryContact', Boolean, false);
        this.prop('secondaryContactId', Number, true);
        this.prop('secondaryContactName', String, true);
        this.prop('secondaryContactEmail', String, true);
        this.prop('secondaryContactPhone', String, true);
        this.prop('secondaryPreferredContactMethod', PreferredContactMethodType, true);
        this.prop('serviceRequestEquipmentDetails', [ServiceRequestEquipmentDetailCreateDTO], true);
        this.prop('serviceRequestIssues', [ServiceRequestIssueCreateDTO], true);

        this.populate(values, config);

    }
    static name = 'ServiceRequestCreateDTO';
}
window[Symbol.for(ServiceRequestCreateDTO.name)] = ServiceRequestCreateDTO;

export default ServiceRequestCreateDTO;