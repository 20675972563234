import Model from './Model.js';

import ImageModel from './ImageModel.js';
import ServiceIssueType from './ServiceIssueType.js';

class ServiceRequestIssueCreateDTO extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('equipmentId', String, true);
        this.prop('images', [ImageModel], false);
        this.prop('serviceIssueType', ServiceIssueType, false);
        this.prop('description', String, true);

        this.populate(values, config);

    }
    static name = 'ServiceRequestIssueCreateDTO';
}
window[Symbol.for(ServiceRequestIssueCreateDTO.name)] = ServiceRequestIssueCreateDTO;

export default ServiceRequestIssueCreateDTO;