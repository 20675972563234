<template>
    <transition name="cross-fade">
    <div class="service-request-flow" v-if="loaded">
        <div class="journey">
            <h2>Service Request</h2>
            <div
                v-for="(step, i) in steps" 
                :key="i" 
                class="step" 
                :class="{'active' : currentStep === parseInt(i), 'ready' : stepValid[i - 1] || parseInt(i) === 1 }"
                @click="(stepValid[i - 1 || i] ? currentStep = parseInt(i) : null); (i === 6 && stepValid[5] ? hasReviewed = true: false)"
            >
                <div>
                    <h3>{{i}}. {{step.title}}</h3>
                    <span>{{step.shortDisplay()}}</span>
                </div>
            </div>
        </div>
        <div class="window">
            <div class="window-header">
                <h1>{{steps[currentStep].title}}</h1>
            </div>
            <div class="window-wrapper">
                <transition-group name="cross-fade">




                <div key="1" class="equipment-selection-step" v-if="currentStep === 1">
                    <div class="equipment-selection-step-wrap panel">
                        <h2 key="1">Select Trailers</h2>
                        
                        <div class="card-search" key="2">
                            <i class="fas fa-search"></i>
                            <input type="text" v-model="searchTerm" placeholder="Enter a trailer number" />
                        </div>
                        <div class="equipment-cards cards cross-fade-wrapper" key="a"> 
                            <transition-group name="cross-fade" appear>
                                <a 
                                class="card" 
                                v-for="id in filteredEquipmentData" 
                                :key="id" 
                                @click="toggleEquipment(id)"
                                >
                                    <h4>
                                        <img src="@/assets/images/brand/star.svg" alt="Star Leasing" v-if="equipment[id].valueof.owner === 1" />
                                        <div v-html="equipment[id].trailerNumber.replace(searchTerm, `<span>${searchTerm}</span>`)"></div>
                                    </h4>
                                    <div>{{equipment[id].year}} {{equipment[id].make.name}} {{equipment[id].nominalLength ? `${equipment[id].nominalLength}'` : ''}} {{equipment[id].description}}</div>
                                    <div class="service-requests"><format-data :record="equipment[id]" column="activeServiceRequests.status" :settings="{NA: '', multiple: 'list'}"></format-data></div>
                                    <div>Next PM: <format-data :record="equipment[id]" :column="'nextPMDate'"></format-data></div>
                                </a>
                                <div key="a" v-if="filteredEquipmentData.length === 0 && searchTerm.length > 2">
                                    No equipment found matching "{{searchTerm}}".
                                </div>
                            </transition-group>
                        </div>


                        <div key="b" v-if="foreignOpen">
                            <h2>Enter trailer details</h2>
                            <div class="form-group">
                                <label>
                                    <span>Trailer number</span>
                                    <input type="text" v-model="holdForeignTrailer.trailerNumber" />
                                </label>
                            </div>
                            <div class="form-group">
                                <label>
                                    <span>Trailer leasing/rental company</span>
                                    <input type="text" v-model="holdForeignTrailer.lessor" />
                                </label>
                            </div>
                            <div class="form-group">
                                <label>
                                    <span>Account for this trailer</span>
                                    <select v-model="holdForeignTrailer.ownerAccountId">
                                        <option v-for="account in clientAccounts" :key="account.id" :value="account.id">{{account.name}}</option>
                                    </select>
                                </label>
                            </div>

                            <div class="form-controls">
                                <button class="button-2" @click="foreignOpen = false;">Cancel</button>
                                <button :disabled="!holdForeignTrailer.trailerNumber" @click="foreignOpen = false; toggleNewEquipment();">Add</button>
                            </div>
                        </div>


                        <div v-else class="foreign-unit-prompt" key="c">
                            <h4>No Star contract?</h4>
                            <button @click="foreignOpen = true; searchTerm = '';">Enter trailer details instead</button>
                        </div>
                    </div>
                    <div v-show="equipmentSelections.length">
                        <div class="equipment-selected panel">
                            <h2>You have selected:</h2>
                            <transition-group name="cross-fade" tag="ul">
                                <li
                                    v-for="trailer in equipmentSelections"
                                    :key="trailer.id"
                                    @click="Object.keys(serviceRequest.newEquipment).includes(trailer.id) ? toggleNewEquipment(trailer.id) :  toggleEquipment(trailer.id)"
                                >
                                    {{trailer.trailerNumber}} {{trailer.make ? `- ${trailer.make.name}` : '' }} {{trailer.nominalLength ? `${trailer.nominalLength}'` : ''}} {{trailer.description}}<i class="fa-regular fa-xmark"></i>
                                </li>
                            </transition-group>
                        </div>
                    </div>
                </div>

                <div key="2" class="service-location-step" v-if="currentStep === 2">
                    <div key="a1" class="panel">
                        <h2 v-if="serviceRequest.serviceLocationType === null" key="0">Service location for {{multipleEquipment ? `${Object.keys(equipmentSelections).length} trailers` : `#${equipmentSelections[fSEI].trailerNumber}`}}</h2>
                        <h2 v-if="serviceRequest.serviceLocationType !== null" key="1">{{serviceRequest.serviceLocationType === 'shop' ? 'Star Facility Service': 'On-site Service'}} for {{multipleEquipment ? `${Object.keys(equipmentSelections).length} trailers` : `#${equipmentSelections[fSEI].trailerNumber}`}}</h2>
                        <transition-group name="cross-fade">
                        
                            <!-- initial state -->
                            <div key="a1">
                                <h4>Would you like us to service the {{multipleEquipment ? 'trailers' : 'trailer' }} on site, or in a Star facility?</h4>
                                <div class="form-group radio-line">
                                    <label><input type="radio" v-model="serviceRequest.valueof.serviceLocationType" :value="2" /><span>On site</span></label>
                                    <label><input type="radio" v-model="serviceRequest.valueof.serviceLocationType" :value="1" /><span>In a Star facility</span></label>
                                </div>
                            </div>
                            
                            <!-- facility : select facility -->
                            <div key="a3" v-if="serviceRequest.serviceLocationType === 'shop'">
                                <h4>Which Star facility would you like to use?</h4>
                                <div class="form-group">
                                    <ul class="form-group radio-buttons split-group">
                                        <select v-model="serviceRequest.shopLocationId">
                                            <option v-for="(option, key) in shopLocations" :key="key" :value="option.id">{{option.name}}: {{option.city}} {{option.state}}</option>
                                        </select>
                                    </ul>
                                </div>
                            </div>

                            <!-- Final in facility -->
                            <div key="a4" v-if="serviceRequest.serviceLocationType === 'shop' && serviceRequest.shopLocationId !== null">
                                <h4>How can we receive {{multipleEquipment ? 'these trailers' : 'the trailer' }}?</h4>
                                <div class="form-group radio-line">
                                    <label><input type="radio" v-model="serviceRequest.requestTowing" :value="false" /><span>I will deliver {{multipleEquipment ? "all the trailers" : "the trailer"}}</span></label>
                                    <label><input type="radio" v-model="serviceRequest.requestTowing" :value="true" /><span>I need {{multipleEquipment ? "all the trailers" : "the trailer"}} picked up</span></label>
                                </div>
                            </div>
                        
                            <!-- non-facility, or facility pickup for one : select location -->
                            <div key="a2" v-if="serviceRequest.serviceLocationType === 'on site' || serviceRequest.requestTowing">
                                <h4>Where {{multipleEquipment ? "are these trailers" : "is this trailer"}} located?</h4>
                                <div class="form-group radio-line">
                                    <label v-if="!multipleEquipment && equipmentSelections[fSEI].gpsEquipped"><input type="radio" v-model="holdTrailerLocationType" :value="0" /><span>GPS</span></label>
                                    <label><input type="radio" v-model="holdTrailerLocationType" :value="1" /><span>Enter location</span></label>
                                    <label v-if="Object.keys(savedLocations).length"><input type="radio" v-model="holdTrailerLocationType" :value="2" /><span>Saved location</span></label>
                                    <label v-if="!multipleEquipment"><input type="radio" v-model="holdTrailerLocationType" :value="3" /><span>Highway</span></label>
                                </div>
                                <transition-group name="cross-fade">
                                    <div key="b1" v-if="holdTrailerLocationType === 0" class="form-group">
                                        <dl><dt>Latitude</dt><dd>{{gpsData[equipmentSelections[fSEI].id].latitude}}</dd></dl>
                                        <dl><dt>Longitude</dt><dd>{{gpsData[equipmentSelections[fSEI].id].longitude}}</dd></dl>
                                        <dl><dt>Location</dt><dd>{{gpsData[equipmentSelections[fSEI].id].location}}</dd></dl>
                                        <dl><dt>Status</dt><dd>{{gpsData[equipmentSelections[fSEI].id].motion_status}}</dd></dl>
                                        <dl><dt>Last updated</dt><dd>{{gpsData[equipmentSelections[fSEI].id].updated_on}}</dd></dl>
                                    </div>

                                    <div v-if="holdTrailerLocationType === 1">
                                        <div class="form-group">
                                            <label>
                                                <input type="text" v-model="serviceRequest.newServiceLocation.street" placeholder="Street" />
                                            </label>
                                        </div>
                                        <div class="form-group inline-group">
                                            <label>
                                                <input type="text" v-model="serviceRequest.newServiceLocation.city" placeholder="City" />
                                            </label>
                                            <label>
                                                <input type="text" v-model="serviceRequest.newServiceLocation.state" placeholder="State" />
                                            </label>
                                            <label>
                                                <input type="text" v-model="serviceRequest.newServiceLocation.zipCode" placeholder="Zipcode" />
                                            </label>
                                        </div>
                                        <div class="form-group inline-group">
                                            <label>
                                                <span>Location Name (optional)</span>
                                                <input type="text" v-model="serviceRequest.newServiceLocation.name" placeholder="Location nickname" />
                                            </label>
                                            <button @click="saveNewLocation" :disabled="!serviceRequest.newServiceLocation.name">Save location</button>
                                        </div>
                                    </div>

                                    <div v-if="holdTrailerLocationType === 2">
                                        <transition-group name="cross-fade">
                                            <div key="b1" class="form-group">
                                                <select v-model="serviceRequest.serviceLocationId">
                                                    <option v-for="id in savedLocations" :key="id" :value="id">{{serviceLocations[id].name}} - {{serviceLocations[id].city}}, {{serviceLocations[id].state}}</option>
                                                </select>
                                            </div>
                                            <div key="b2" v-if="serviceRequest.serviceLocationId !== null && !updateSavedLocation" class="Form-group">
                                                <p>{{serviceLocations[serviceRequest.serviceLocationId].street}}, {{serviceLocations[serviceRequest.serviceLocationId].city}}, {{serviceLocations[serviceRequest.serviceLocationId].state}} {{serviceLocations[serviceRequest.serviceLocationId].zipCode}} <a @click.prevent="beginUpdateSavedLocation"><i class="fa-light fa-pen-to-square"></i></a></p>
                                            </div>
                                            <div key="b3" class="form-group" v-if="serviceRequest.serviceLocationId !== null && updateSavedLocation">
                                                <label>
                                                    <input type="text" v-model="updateLocationDTO.name" placeholder="Location nickname" />
                                                </label>
                                            </div>
                                            <div key="b4" class="form-group" v-if="serviceRequest.serviceLocationId !== null && updateSavedLocation">
                                                <label>
                                                    <input type="text" v-model="updateLocationDTO.street" placeholder="Street" />
                                                </label>
                                            </div>
                                            <div key="b5" class="form-group inline-group" v-if="trailerLocation !== null && updateSavedLocation">
                                                <label>
                                                    <input type="text" v-model="updateLocationDTO.city" placeholder="City" />
                                                </label>
                                                <label>
                                                    <input type="text" v-model="updateLocationDTO.state" placeholder="State" />
                                                </label>
                                                <label>
                                                    <input type="text" v-model="updateLocationDTO.zipCode" placeholder="Zipcode" />
                                                </label>
                                            </div>
                                            <div key="b6" class="form-group" v-if="serviceRequest.serviceLocationId !== null && updateSavedLocation">
                                                <button class="button-3" @click="deleteSavedLocation">Delete This Location</button>
                                                <button class="button-2" @click="cancelUpdateSavedLocation">Cancel Changes</button>
                                                <button @click="submitUpdateSavedLocation">Save Changes</button>
                                            </div>
                                        </transition-group>
                                    </div>

                                    <div v-if="holdTrailerLocationType === 3">
                                        <p>Cool map thing</p>
                                    </div>
                                </transition-group>
                            </div>
                        </transition-group>
                    </div>
                </div>

                <div key="3" class="service-schedule-step" v-if="currentStep === 3">
                    <div class="panel">
                        <transition-group name="cross-fade">
                            <h2 key="0">Service schedule for {{multipleEquipment ? `${Object.keys(equipmentSelections).length} trailers` : `#${equipmentSelections[fSEI].trailerNumber}`}}</h2>
                            <div key="1" v-if="multipleEquipment === false">
                                <h4>{{multipleEquipment ? 'Do the trailers' : 'Does the trailer'}} require immediate service?</h4>
                                <div class="form-group">
                                    <label><input type="radio" v-model="serviceRequest.urgentRequest" :value="true" /><span>Yes, this is an urgent service request*</span></label>
                                    <label><input type="radio" v-model="serviceRequest.urgentRequest" :value="false" /><span>No, this is a standard service request</span></label>
                                </div>
                            </div>
                            
                            <div key="2" v-if="serviceRequest.urgentRequest === false">
                                <h4 v-if="serviceRequest.serviceLocationType === 'shop' && serviceRequest.requestTowing === true">When can we pick up the {{multipleEquipment ? "trailers" : "trailer" }}?</h4> 
                                <h4 v-if="serviceRequest.serviceLocationType === 'shop' && serviceRequest.requestTowing === false">When can you deliver the {{multipleEquipment ? "trailers" : "trailer" }} to the {{shopLocations[serviceRequest.shopLocationId].name}} Star facility?</h4>
                                <h4 v-if="serviceRequest.serviceLocationType === 'on site'">When can we begin service on the {{multipleEquipment ? "trailers" : "trailer" }}?</h4>
                                <div class="form-group">
                                    <label>
                                        <input type="checkbox" v-model="serviceRequest.availableImmediately" /><span>Available now</span>
                                    </label>
                                </div>
                                <transition name="autocordion">
                                    <div class="autocordion" v-if="serviceRequest.urgentRequest === false && serviceRequest.availableImmediately !== true">
                                        <div>
                                            <div class="form-group">
                                                <date-time-picker v-model="serviceRequest.startTime" :min-date="now" :step="15"></date-time-picker>
                                            </div>
                                        </div>
                                    </div>
                                </transition>
                                <h4>When do repairs need to be completed by?</h4>
                                <div class="form-group">
                                    <date-time-picker v-model="serviceRequest.endTime" :min-date="serviceRequest.availableImmediately ? new Date(new Date().getTime()  + 5 * 60 * 60 * 1000) : new Date (serviceRequest.startTime.getTime() + 5 * 60 * 60 * 1000)" :step="15"></date-time-picker>
                                </div>
                            </div>

                            <div key="6" v-if="serviceRequest.urgentRequest === true">
                                <h4>Service will be scheduled ASAP</h4>
                            </div>
                                
                                <!-- when needs to be repaired by -->
                            <div key="8" v-if="serviceRequest.urgentRequest !== false" class="footnote">
                                <p>
                                *An Urgent service request is any request where a trailer's issues must be prioritized over other repairs, 
                                such as a broken trailer blocking a yard entrance or creating other hazardous conditions; or when a driver is with the trailer and 
                                cannot leave until the trailer can be moved. Urgent requests may incur an "Urgent Service Request" upcharge according to your 
                                equipment lease or service agreement. This option is not intended as a way to prioritize non-urgent repairs.
                                </p>
                            </div>
                        </transition-group>
                    </div>
                </div>
                
                <div key="4" class="equipment-issues-step" v-if="currentStep === 4">
                    <div class="panel">                        
                        <h2>Equipment issues for {{multipleEquipment ? `${Object.keys(equipmentSelections).length} trailers` : `#${equipmentSelections[fSEI].trailerNumber}`}}</h2>
                        <div v-for="(equipment) in equipmentSelections" :key="equipment.id" class="split-service-selector cross-fade-wrapper">
                            <transition-group name="cross-fade">
                                <div :key="`${equipment.id}-1`">
                                    <h4>Information for trailer #{{equipment.trailerNumber}}</h4>
                                    
                                    <div class="form-group radio-line">
                                        <span>Is this trailer loaded?</span>
                                        <label><input type="radio" v-model="serviceRequest.serviceRequestEquipmentDetails[equipment.id].loaded" :value="true" /><span>Yes</span></label>
                                        <label><input type="radio" v-model="serviceRequest.serviceRequestEquipmentDetails[equipment.id].loaded" :value="false" /><span>No</span></label>
                                    </div>
                                    <div class="form-group radio-line">
                                        <span>Is this trailer carrying hazardous material?</span>
                                        <label><input type="radio" v-model="serviceRequest.serviceRequestEquipmentDetails[equipment.id].hazardous" :value="true" /><span>Yes</span></label>
                                        <label><input type="radio" v-model="serviceRequest.serviceRequestEquipmentDetails[equipment.id].hazardous" :value="false" /><span>No</span></label>
                                    </div>
                                    <div class="form-group radio-line">
                                        <span>Do you need an estimate for this trailer?</span>
                                        <label><input type="radio" v-model="serviceRequest.serviceRequestEquipmentDetails[equipment.id].forceEstimate" :value="true" /><span>Yes</span></label>
                                        <label><input type="radio" v-model="serviceRequest.serviceRequestEquipmentDetails[equipment.id].forceEstimate" :value="false" /><span>No</span></label>
                                    </div>
                                    <div class="form-group radio-line">
                                        <span>Is the driver with the trailer?</span>
                                        <label><input type="radio" v-model="serviceRequest.serviceRequestEquipmentDetails[equipment.id].driverPresent" :value="true" /><span>Yes</span></label>
                                        <label><input type="radio" v-model="serviceRequest.serviceRequestEquipmentDetails[equipment.id].driverPresent" :value="false" /><span>No</span></label>
                                    </div>
                                </div>
                                <div :key="`${equipment.id}-2`" v-if="serviceRequest.serviceRequestEquipmentDetails[equipment.id].driverPresent">
                                    <div class="form-group">
                                        <label>
                                            Driver name
                                            <input type="text" v-model="serviceRequest.serviceRequestEquipmentDetails[equipment.id].driverName" />
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label>
                                            Driver phone number
                                            <input type="text" v-model="serviceRequest.serviceRequestEquipmentDetails[equipment.id].driverPhoneNumber" />
                                        </label>
                                    </div>
                                </div>
                                <div :key="`${equipment.id}-3`" class="split">
                                    <div>
                                        <h4>What Type of Service is Needed?</h4>
                                        <div class="form-group capitalize">
                                            <build-select 
                                                :options="ServiceIssueType" 
                                                v-model="holdEquipmentIssues[equipment.id]"
                                                name="issue"
                                                placeholder="Select issues"
                                            >
                                            </build-select>
                                        </div>
                                        <div class="form-group">
                                            <textarea v-model="holdEquipmentIssueDescriptions[equipment.id]" placeholder="Description/Position of the issue"></textarea>
                                        </div>
                                    </div>
                                    <div>
                                        <h4>Upload damage photos</h4>
                                        <file-input :fileTypes="['.jpg', '.png']" name="bulk-upload" @change="holdEquipmentIssueImages[equipment.id] = $event.target.value" :multiple="true"></file-input>
                                    </div>
                                </div>


                                <!-- More details for foreign trailers -->
                                <div :key="`${equipment.id}-4`" v-if="Object.keys(serviceRequest.newEquipment).includes(equipment.id)">
                                    <h4>Additional details (optional)</h4>
                                    <ul class="split-list">
                                        <li class="form-group">
                                            <label>
                                                <span>Make</span>
                                                <select @input="serviceRequest.newEquipment[equipment.id].make = parseInt($event.target.value)">
                                                    <option :value="null"></option>
                                                    <option v-for="make in makeData" :key="make.id" :value="make.id" :selected="serviceRequest.newEquipment[equipment.id].make && serviceRequest.newEquipment[equipment.id].make.id === make.id">{{make.name}}</option>
                                                </select>
                                            </label>
                                        </li>
                                        <li class="form-group">
                                            <label>
                                                <span>Year</span>
                                                <input type="number" v-model.number="serviceRequest.newEquipment[equipment.id].year" />
                                            </label>
                                        </li>
                                        <li class="form-group">
                                            <label>
                                                <span>Length (ft)</span>
                                                <input type="number" v-model="serviceRequest.newEquipment[equipment.id].nominalLength" />
                                            </label>
                                        </li>
                                        <li class="form-group">
                                            <label>
                                                <span>VIN</span>
                                                <input type="text" v-model="serviceRequest.newEquipment[equipment.id].vin" />
                                            </label>
                                        </li>

                                        <li class="form-group" v-if="holdEquipmentIssues[equipment.id].indexOf('3') !== -1">
                                            <label>
                                                <span>Tire Size</span>
                                                <select>
                                                    <option :value="null"></option>
                                                    <option value="0">295 75 22.5</option>
                                                    <option value="1">275 80 22.5</option>
                                                    <option value="2">11R 22.5</option>
                                                    <option value="3">11R 24.5</option>
                                                    <option value="4">455 55 22.5</option>
                                                    <option value="5">255 70 22.5</option>
                                                    <option value="6">Other</option>
                                                    <option value="7">Unknown</option>
                                                </select>
                                            </label>
                                        </li>
                                        <li class="form-group" v-if="holdEquipmentIssues[equipment.id].indexOf('11') !== -1">
                                            <label>
                                                <span>Wall Type</span>
                                                <select @input="serviceRequest.newEquipment[equipment.id].wallType = $event.target.value">
                                                    <option :value="null"></option>
                                                    <option v-for="(v, k) in serviceRequest.newEquipment[equipment.id].typeof.wallType" :selected="serviceRequest.newEquipment[equipment.id].wallType === v" :key="k" :value="k">{{v}}</option>
                                                </select>
                                            </label>
                                        </li>
                                        <li class="form-group" v-if="holdEquipmentIssues[equipment.id].indexOf('10') !== -1">
                                            <label>
                                                <span>Door Type</span>
                                                <select @input="serviceRequest.newEquipment[equipment.id].doorType = $event.target.value">
                                                    <option :value="null"></option>
                                                    <option v-for="(v, k) in serviceRequest.newEquipment[equipment.id].typeof.doorType" :selected="serviceRequest.newEquipment[equipment.id].doorType === v" :key="k" :value="k">{{v}}</option>
                                                </select>
                                            </label>
                                        </li>
                                        <li class="form-group" v-if="holdEquipmentIssues[equipment.id].indexOf('2') !== -1">
                                            <label>
                                                <span>Suspension Type</span>
                                                <select @input="holdForeignTrailer.suspensionType = $event.target.value">
                                                    <option :value="null"></option>
                                                    <option v-for="(v, k) in serviceRequest.newEquipment[equipment.id].typeof.suspensionType" :selected="serviceRequest.newEquipment[equipment.id].suspensionType === v" :key="k" :value="k">{{v}}</option>
                                                </select>
                                            </label>
                                        </li>
                                            
                                    </ul>

                                </div>




                            </transition-group>
                        </div>
                    </div>
                </div>

                <div key="5" class="equipment-issues-step" v-if="currentStep === 5">
                    <div class="panel">
                        <div class="split-service-selector">
                            <h2 key="0">Primary contact information</h2>
                            <div>
                                <div key="1" class="form-group">
                                    <label>
                                        <span>Name</span>
                                        <input type="text" v-model="serviceRequest.primaryContactName" />
                                    </label>
                                </div>
                                <h5 key="2">Preferred Method of Contact</h5>
                                <div key="3" class="form-group radio-line">
                                    <label>
                                        <input type="radio" v-model="serviceRequest.valueof.primaryPreferredContactMethod" :value="0" /><span>Phone</span>
                                    </label>
                                    <label>
                                        <input type="radio" v-model="serviceRequest.valueof.primaryPreferredContactMethod" :value="1" /><span>Email</span>
                                    </label>
                                </div>
                                <div key="4" class="form-group">
                                    <label>
                                        <span>Phone</span>
                                        <input type="text" v-model="serviceRequest.primaryContactPhone" />
                                    </label>
                                </div>
                                <div key="5" class="form-group">
                                    <label>
                                        <span>Email</span>
                                        <input type="text" v-model="serviceRequest.primaryContactEmail" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="split-service-selector">
                            <transition-group name="cross-fade">
                                <div key="6" class="form-group">
                                    <label><input type="checkbox" v-model="serviceRequest.isSecondaryContact" /><span>Add secondary contact</span></label>
                                </div>
                                <h2 key="6a" v-if="serviceRequest.isSecondaryContact">Secondary contact information</h2>
                                <div key="7" v-if="serviceRequest.isSecondaryContact">
                                    <div key="7" class="form-group">
                                        <label>
                                            <span>Name</span>
                                            <input type="text" v-model="serviceRequest.secondaryContactName" />
                                        </label>
                                    </div>
                                    <h5>Preferred Method of Contact</h5>
                                    <div key="8" class="form-group radio-line">
                                        <label>
                                            <input type="radio" v-model="serviceRequest.valueof.secondaryPreferredContactMethod" :value="0" /><span>Phone</span>
                                        </label>
                                        <label>
                                            <input type="radio" v-model="serviceRequest.valueof.secondaryPreferredContactMethod" :value="1" /><span>Email</span>
                                        </label>
                                    </div>
                                    <div key="9" class="form-group">
                                        <label>
                                            <span>Phone</span>
                                            <input type="text" v-model="serviceRequest.secondaryContactPhone" />
                                        </label>
                                    </div>
                                    <div key="10" class="form-group">
                                        <label>
                                            <span>Email</span>
                                            <input type="text" v-model="serviceRequest.secondaryContactEmail" />
                                        </label>
                                    </div>
                                </div>
                            </transition-group>
                        </div>
                    </div>
                </div>




                <div key="6" class="service-review-step" v-if="currentStep === 6">
                    <div class="panel">
                        <h2 key="0">Service location <i @click="currentStep = 2" class="fa-light fa-pen-to-square"></i></h2>
                        <table class="headless-table">
                            <tbody>
                                <tr>
                                    <td>Service location type</td>
                                    <td class="capitalize">{{serviceRequest.serviceLocationType}}</td>
                                </tr>
                                <tr v-if="serviceRequest.serviceLocationType === ServiceLocationType[1]">
                                    <td>Shop</td>
                                    <td v-html="output(
                                        shopLocations[serviceRequest.shopLocationId],
                                        0,
                                        {
                                            format: '<p>Star Facility {name}<br /> {street}<br /> {city}, {state} {zipCode}</p>'
                                        },
                                    )"></td>
                                </tr>
                                <tr v-if="serviceRequest.serviceLocationType === ServiceLocationType[1]">
                                    <td>Transportation</td>
                                    <td>{{serviceRequest.requestTowing ? 'We will tow to the shop.' : 'You are dropping off at the shop.'}}</td>
                                </tr>
                                <tr v-if="serviceRequest.serviceLocationType === ServiceLocationType[2] || serviceRequest.requestTowing === true">
                                    <td>Trailer Location</td>
                                    <td v-if="serviceRequest.serviceLocationId" v-html="output(
                                        serviceLocations[serviceRequest.serviceLocationId],
                                        0,
                                        {
                                            format: '<p>{name}<br /> {street}<br /> {city}, {state} {zipCode}</p>'
                                        }
                                    )"></td>
                                    <td v-else-if="serviceRequest.newServiceLocation.street" v-html="output(
                                        serviceRequest.newServiceLocation,
                                        0,
                                        {
                                            format: '<p>{street}<br /> {city}, {state} {zipCode}</p>'
                                        }
                                    )"></td>
                                    <td v-else v-html="output(
                                        serviceRequest.newServiceLocation,
                                        0,
                                        {
                                            format: 'lat:{latitude}, lng:{longitude}'
                                        }
                                    )"></td>
                                </tr>
                                <tr v-if="serviceRequest.newServiceLocation && serviceRequest.newServiceLocation.saved">
                                    <td>Saving location for future use</td>
                                    <td>{{serviceRequest.newServiceLocation.name}}</td>
                                </tr>
                            </tbody>
                        </table>
                        
                        <h2>Service schedule <i @click="currentStep = 3" class="fa-light fa-pen-to-square"></i></h2>
                        <table class="headless-table">
                            <tbody>
                                <template v-if="serviceRequest.urgentRequest">
                                    <tr>
                                        <td><strong>Urgent Request*</strong></td>
                                        <td>Service will be scheduled ASAP.</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <p>                                
                                            *An Urgent service request is any request where a trailer's issues must be prioritized over other repairs, 
                                            such as a broken trailer blocking a yard entrance or creating other hazardous conditions; or when a driver is with the trailer and 
                                            cannot leave until the trailer can be moved. Urgent requests may incur an "Urgent Service Request" upcharge according to your 
                                            equipment lease or service agreement. This option is not intended as a way to prioritize non-urgent repairs.
                                            </p>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td>Requested start time</td>
                                        <td v-if="serviceRequest.availableImmediately">ASAP</td>
                                        <td v-else>{{serviceRequest.startTime.toLocaleString()}}</td>
                                    </tr>
                                    <tr>
                                        <td>Requested repair complete by</td>
                                        <td>{{serviceRequest.endTime.toLocaleString()}}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>

                        <template v-for="equipment in equipmentSelections" :key="equipment.id">
                            <h2>Trailer #{{equipment.trailerNumber}}  <i @click="currentStep = 1" class="fa-light fa-pen-to-square"></i></h2>
                            <div class="split">
                                <div>
                                    <h4>Issue details <i @click="currentStep = 4" class="fa-light fa-pen-to-square"></i></h4>
                                    <table class="headless-table">
                                        <tbody>
                                            <tr>
                                                <td>Issues</td>
                                                <td class="capitalize">
                                                    <ul 
                                                        v-html="output(
                                                            Object.values(serviceRequest.serviceRequestIssues).filter(x => x.equipmentId === equipment.id+''),
                                                            0,
                                                            {format: '<li>{serviceIssueType}</li>'},
                                                            'serviceIssueType'
                                                        )">

                                                    </ul>
                                                </td>
                                            </tr>
                                            <template v-for="description in [Object.values(serviceRequest.serviceRequestIssues).filter(x => x.equipmentId === equipment.id+'')[0].description]" :key="description">
                                                <tr>
                                                    <td :colspan="description ? 2 : 1">
                                                        Issue description
                                                        <p v-if="description" v-html="description"></p>
                                                    </td>
                                                    <td v-if="!description">None provided</td>
                                                </tr>
                                            </template>
                                            <tr>
                                                <td>Images</td>
                                                <td>{{Object.keys(Object.values(serviceRequest.serviceRequestIssues).filter(x => x.equipmentId === equipment.id+'')[0].images).length || "None provided" }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <h4>Trailer details <i @click="currentStep = 4" class="fa-light fa-pen-to-square"></i></h4>
                                    <table class="headless-table">
                                        <tbody>
                                            <tr>
                                                <td>Loaded</td>
                                                <td>{{serviceRequest.serviceRequestEquipmentDetails[equipment.id].loaded ? 'Yes' : 'No'}}</td>
                                            </tr>
                                            <tr>
                                                <td>Hazardous material</td>
                                                <td>{{serviceRequest.serviceRequestEquipmentDetails[equipment.id].hazardous ? 'Yes' : 'No'}}</td>
                                            </tr>
                                            <tr>
                                                <td>Need an estimate</td>
                                                <td>{{serviceRequest.serviceRequestEquipmentDetails[equipment.id].forceEstimate ? 'Yes' : 'No'}}</td>
                                            </tr>
                                            <tr>
                                                <td>Driver with trailer</td>
                                                <td>{{serviceRequest.serviceRequestEquipmentDetails[equipment.id].driverPresent ? 'Yes' : 'No'}}</td>
                                            </tr>
                                            <tr v-if="serviceRequest.serviceRequestEquipmentDetails[equipment.id].driverPresent">

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </template>
                        
                        <h2>Contact information <i @click="currentStep = 5" class="fa-light fa-pen-to-square"></i></h2>
                        <div :class="{split: serviceRequest.isSecondaryContact}">
                            <div>
                                <h4>Primary contact</h4>
                                <table class="headless-table">
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>{{serviceRequest.primaryContactName}}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td>{{serviceRequest.primaryContactPhone || 'None provided'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>{{serviceRequest.primaryContactEmail || 'None provided'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Pref. contact</td>
                                            <td>{{serviceRequest.primaryPreferredContactMethod}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <h4 v-if="serviceRequest.isSecondaryContact">Secondary contact</h4>
                                <table v-if="serviceRequest.isSecondaryContact" class="headless-table">
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>{{serviceRequest.secondaryContactName}}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td>{{serviceRequest.secondaryContactPhone || 'None provided'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>{{serviceRequest.secondaryContactEmail || 'None provided'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Pref. contact</td>
                                            <td>{{serviceRequest.secondaryPreferredContactMethod}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-group>
            </div>

            <!-- <div style="height: 400px !important;overflow: auto;"><pre>{{serviceRequest}}</pre></div> -->
            <div class="window-controls form-controls">
                <button @click.prevent="currentStep > 1 ? currentStep-- : null" class="button-2" v-if="currentStep > 1">Back to {{steps[currentStep - 1].title}}</button>
                <button v-if="currentStep !== 6" @click.prevent="nextStep" :disabled="!stepValid[currentStep]">Continue</button>
                <button v-else @click.prevent="submit">Submit Service Request</button>
                <button v-if="hasReviewed && currentStep !== 6 && stepValid[5]" @click.prevent="currentStep = 6">Back to Review</button>
            </div>
        </div>
        <overlay v-if="overlay" @close="reset">
            <template v-slot:content>
                <h2>Request {{newRequestId}} created</h2>
                <p>Your request has been created and is being processed.</p>
                <div class="form-controls">
                    <router-link class="button" :to="`/service-history/${newRequestId}`">View Request Details</router-link>
                    <button @click="reset">Make Another Service Request</button>
                    <router-link class="button" to="/">Home</router-link>
                </div>
            </template>
        </overlay>
    </div>
    </transition>
</template>

<script>
import EquipmentModel from '../models/EquipmentModel';
import GPSModel from '../models/GPSModel';
import ServiceLocationModel from '../models/ServiceLocationModel'
import ShopLocationModel from '../models/ShopLocationModel';

import ServiceRequestCreateDTO from '../models/ServiceRequestCreateDTO';
import EquipmentCreateDTO from '../models/EquipmentCreateDTO';
import ServiceRequestEquipmentDetailCreateDTO from '../models/ServiceRequestEquipmentDetailCreateDTO';

import ServiceLocationType from '../models/ServiceLocationType';
import ServiceIssueType from '../models/ServiceIssueType';

import DateTimePicker from '../components/inputs/DateTimePicker.vue';
import FileInput from '../components/inputs/FileInput.vue';

import axiosInstance from '../connections/AxiosInstance';
import ServiceLocationCreateDTO from '../models/ServiceLocationCreateDTO';
import ServiceRequestIssueCreateDTO from '../models/ServiceRequestIssueCreateDTO';
import Model from '../models/Model';
import Enum from '../models/Enum';
import ClientAccountModel from '../models/ClientAccountModel';
import MakeModel from '../models/MakeModel';
import FormatDataMixin from '../mixins/FormatData';
import ServiceRequestModel from '../models/ServiceRequestModel';
import Overlay from '../components/utilities/Overlay.vue';
import FormatData from '../components/utilities/FormatData.vue';
import database from "@/store/database";

import {reactive, watch} from '@vue/runtime-core';
import {ref} from "@vue/reactivity";
import {useRoute} from "vue-router";
import store from "@/store/store";
import BuildSelect from "@/components/inputs/BuildSelect";
import {shallowReactive} from "vue";


export default {
    components: {
        BuildSelect,
        DateTimePicker,
        FileInput,
        Overlay,
        FormatData,
    },
    data: function () {
        return {
            ServiceLocationType,
            hasReviewed: false,

            
            
            foreignOpen: false,
            overlay: false,
            newRequestId: 0, 



            updateLocationDTO: null,

            holdTrailerLocationType: null,
            updateSavedLocation: false,

            //Equipment Selection
            pmFilter: null,

            
            //Service Schedule
            now: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000),
            tomorrow: new Date(Math.ceil(Date.UTC() / (24 * 60 * 60 * 1000)) * (24 * 60 * 60 * 1000)),


            //EquipmentIssues
            driverInSystem: {},
            ServiceIssueType,

            steps: {
                1: {
                    title: 'Equipment Selection',
                    shortDisplay: () => {
                        if (Object.keys(this.equipmentSelections).length === 1){
                            return `You have selected trailer #${this.equipmentSelections[this.fSEI].trailerNumber}`;
                        }
                        if (Object.keys(this.equipmentSelections).length > 1){
                            return `You have selected ${Object.keys(this.equipmentSelections).length} trailers`;
                        }
                        return 'Choose the equipment that needs service';
                    },
                },
                2: {
                    title: 'Service Location',
                    shortDisplay: () => {
                        if (this.serviceRequest.serviceLocationType === 'shop' && this.serviceRequest.shopLocationId){
                            return `The ${this.multipleEquipment ? 'trailers' : 'trailer'} will be serviced at the ${this.shopLocations[this.serviceRequest.shopLocationId].name} Star facility`;
                        }
                        if (!this.serviceRequest.serviceLocationType === 'on site' && this.serviceRequest.serviceLocationId){
                            return `The ${this.multipleEquipment ? 'trailers' : 'trailer'} will be serviced at ${this.savedLocations[this.serviceRequest.serviceLocationId]}`;
                        }
                        if (!this.serviceRequest.serviceLocationType === 'on site' && this.serviceRequest.newServiceLocation){
                            return `The ${this.multipleEquipment ? 'trailers' : 'trailer'} will be serviced at ${this.serviceRequest.newServiceLocation.street}`;
                        }

                        return 'Tell us where you would like the service performed';
                    },
                },
                3: {
                    title: 'Service Schedule',
                    shortDisplay: () => {
                        if (this.serviceRequest.urgentRequest){
                            return 'This is an urgent service request*';
                        } else if (this.serviceRequest.availableImmediately){
                            return 'This request will be scheduled as soon as possible';
                        }
                        return 'Tell us when you would like us to perform the service';
                    },
                },
                4: {
                    title: 'Equipment Issues',
                    shortDisplay: () => {
                        return 'Tell us what service or services are needed';
                    },
                },
                5: {
                    title: 'Contact Information',
                    shortDisplay: () => {
                        return 'Tell us our primary point of contact';
                    },
                },
                6: {
                    title: 'Review',
                    shortDisplay: () => {
                        return 'Review the information you provided';
                    },
                }
            },
        }
    },  
    setup(){
        const {output} = FormatDataMixin();
        const route = useRoute();


        const promises = [];
        const loaded = ref(false);
        const currentStep = ref(1);
        const minSearchLength = 2;
        
        const searchTerm = ref('');

        let holdEquipmentIssues = reactive({});
        let holdEquipmentIssueDescriptions = reactive({});
        let holdEquipmentIssueImages = reactive({});
        let holdForeignTrailer = reactive(new EquipmentCreateDTO({
            ownerAccountId: store.state.UserProfile.clientAccount
        }));
        
        const savedLocations = reactive([]);
        //get a list of saved locations 
        promises.push(axiosInstance({
            method: 'get',
            url: 'ServiceLocation/SavedLocations',
        }).then((response) => {
            savedLocations.push(...response.data);
            //load these locations now
            serviceLocations.load(savedLocations);
            return response;
            
        }, (error) => {
            console.log(error);
            throw error; //!!Better error handling
        }));



        let serviceRequest = reactive(new ServiceRequestCreateDTO({
            equipment: [],
            startTime: new Date(),
            endTime: new Date(),
            isSecondaryContact: false,
            _edit: true,
        }));


        const setEquipment = (id, up) => {
            if (up){
                serviceRequest.serviceRequestEquipmentDetails[id] = new ServiceRequestEquipmentDetailCreateDTO({
                    equipmentId : id+''
                });
                holdEquipmentIssues[id] = [];
                holdEquipmentIssueDescriptions[id] = "";
                holdEquipmentIssueImages[id] = [];
            } else {
                delete serviceRequest.serviceRequestEquipmentDetails[id];
                delete holdEquipmentIssues[id];
                delete holdEquipmentIssueDescriptions[id];
                delete holdEquipmentIssueImages[id];
            }
        }
        const toggleEquipment = (id) => {
            let ind = serviceRequest.equipment.indexOf(id);
            if (ind === -1){
                serviceRequest.equipment.push(id);
                setEquipment(id, true);
            } else {
                serviceRequest.equipment.splice(ind, 1);
                setEquipment(id, false);
            }
            return id;
        };
        const toggleNewEquipment = (tempId) => {
            tempId = tempId || Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            if (!Object.keys(serviceRequest.newEquipment).includes(tempId)){
                serviceRequest.newEquipment.add(tempId, new EquipmentCreateDTO(holdForeignTrailer));
                serviceRequest.newEquipment[tempId].id = tempId;
                setEquipment(tempId, true);
            } else {
                delete serviceRequest.newRequest[tempId];
                setEquipment(tempId, false);
            }
            return tempId;
        };









        const equipment = database[EquipmentModel.name];
        promises.push(equipment.all());

        const makes = database[MakeModel.name];

        const gpsData = reactive({});
        promises.push(axiosInstance.getAll(GPSModel).then((response) => {
            Object.assign(gpsData, response.data);
            return response;
        }));

        const clientAccounts = database[ClientAccountModel.name];
        promises.push(clientAccounts.all());

        const shopLocations = database[ShopLocationModel.name];

        const serviceLocations = database[ServiceLocationModel.name];




        const equipmentSelections = shallowReactive([]);
        watch([serviceRequest], () => {
            let build = [...serviceRequest.equipment.map(x => equipment[x])];
            if (serviceRequest.newEquipment.length){
                build.push(...Object.values(serviceRequest.newEquipment));
            }
            equipmentSelections.length = 0;
            equipmentSelections.push(...build);
            filterEquipmentList();
        }, {deep: true});
        
        
        const filteredEquipmentList = shallowReactive([]);
        const filterEquipmentList = () => {
            filteredEquipmentList.length = 0;
            if (!equipment || searchTerm.value.length < minSearchLength){
                return;
            }
            let searchToLower = searchTerm.value.toLowerCase();
            filteredEquipmentList.push(
                ...equipment.array.filter((x) => {
                    return x.trailerNumber.toLowerCase().indexOf(searchToLower) !== -1
                        && !equipmentSelections.some(y => y.id === x.id);
                }).map(x => x.id)
            );
        };
        watch([equipment.keys, searchTerm], filterEquipmentList, {deep: false});
        



        Promise.all(promises).then(() => {
            loaded.value = true;
            if(route.params.id){
                toggleEquipment(route.params.id);
                currentStep.value = 2;
            }
        });

        return {
            output,
            toggleEquipment,
            toggleNewEquipment,
            
            loaded,
            currentStep,
            searchTerm,

            serviceRequest,
            equipmentSelections,
            filteredEquipmentData: filteredEquipmentList,
            holdEquipmentIssues,
            holdEquipmentIssueDescriptions,
            holdEquipmentIssueImages,
            holdForeignTrailer,
            

            equipment, 
            makes,
            gpsData,
            clientAccounts,
            shopLocations,
            savedLocations,
            serviceLocations,
        };
    },
    watch: {
        holdEquipmentIssues: { 
            handler: function(){
                let goodOnes = [];
                //ensure we have a record for each service issue on each equipment
                for (var equipmentId in this.holdEquipmentIssues){
                    for (var issue in this.holdEquipmentIssues[equipmentId]){
                        let comboId = `${equipmentId}-${this.holdEquipmentIssues[equipmentId][issue]}`;
                        if (!Object.hasOwnProperty.call(this.serviceRequest.serviceRequestIssues, comboId)){
                            this.serviceRequest.serviceRequestIssues[comboId] = new ServiceRequestIssueCreateDTO({
                                serviceIssueType: this.holdEquipmentIssues[equipmentId][issue],
                                description: this.holdEquipmentIssueDescriptions[equipmentId],
                                //!! Images go here
                                equipmentId: equipmentId,
                            });
                        }
                        goodOnes.push(comboId);
                    }
                }
                //remove any that aren't in our holding object any longer
                for (var exisitngIssue in this.serviceRequest.serviceRequestIssues){
                    if (goodOnes.indexOf(exisitngIssue) === -1){
                        delete this.serviceRequest.serviceRequestIssues[exisitngIssue];
                    }
                }
            },
            deep: true
        },
        holdEquipmentIssueDescriptions: {
            handler: function(descriptions) {
                for (var id in descriptions){
                    let issues = this.serviceRequest.serviceRequestIssues.where(x => x.equipmentId+'' === id);
                    for (var i in issues){
                        issues[i].description = descriptions[id];
                    }
                } 
            },
            deep: true
        },
        holdTrailerLocationType (){
            if (this.holdTrailerLocationType === 0){
                const selectedGpsData = this.gpsData[this.equipmentSelections[this.fSEI].id];
                this.serviceRequest.newServiceLocation = new ServiceLocationCreateDTO({
                    saved: false,
                    name: selectedGpsData.location,
                    latitude: selectedGpsData.latitude,
                    longitude: selectedGpsData.longitude
                });
            } else if (this.holdTrailerLocationType === 1 || this.holdTrailerLocationType === 3){
                this.serviceRequest.newServiceLocation = new ServiceLocationCreateDTO({
                    saved: false,
                })
            } else if (this.holdTrailerLocationType === 2){
                this.serviceRequest.newServiceLocation = null;
            }
        },
    },
    methods: {
        saveNewLocation(){
            this.serviceRequest.newServiceLocation.saved = true;
            console.log(this.toJsonPayload(this.serviceRequest.newServiceLocation));

            let payload = this.toJsonPayload(this.serviceRequest.newServiceLocation);
            axiosInstance({
                method: 'post',
                url: `${ServiceLocationModel.source}/Create/Client`,
                data: payload,
            }).then((response) => {
                console.log(response);
                this.serviceRequest.serviceLocationId = response.data;
                this.holdTrailerLocationType = 2;
                return response;
            }, (error)=> {
                alert("!!Something went wrong");
                for (var prop in error.errors){
                    if (prop.indexOf("$.") === 0){
                        let fixedProp = prop.substring(2);
                        if (Object.hasOwnProperty.call(this.serviceRequest, fixedProp)){
                            this.serviceRequest._error[fixedProp] = error.errors[prop];
                        }
                    }
                }
                console.log(error);
                throw error;
            });
        },
        deleteSavedLocation(){
            if (confirm(`This action will irreversably delete the saved location "${this.savedLocations[this.serviceRequest.serviceLocationId].name}".`)){
                var id = this.serviceRequest.serviceLocationId;
                axiosInstance({
                    method: 'post',
                    url: `${ServiceLocationModel.source}/Delete/Client`,
                    data: {id: id},
                }).then((response) => {
                    if (response.data === true){
                        this.updateSavedLocation = false;
                        this.updateLocationDTO = null;
                        this.serviceRequest.serviceLocationId = null;
                        delete this.$store.state.AppData[ServiceLocationModel.name][id];
                    } else {
                        alert("!!Delete didn't work");
                    }
                }, (error) => {
                    throw error;
                });  
            }
        },

        

        nextStep(){
            if (this.stepValid[this.currentStep]){
                this.currentStep++;
                if (this.currentStep === 6){
                    this.hasReviewed = true;
                }
            }
        },
        toJsonPayload(model, level = 0){
            if (level > 5){
                return;
            }
            let payload = {};
            for (var prop in model){

                if (Array.isArray(model.typeof[prop]) && model.typeof[prop][0].prototype instanceof Model){
                    payload[prop] = [];
                    for (var id in model[prop]){
                        payload[prop].push(this.toJsonPayload(model[prop][id], level + 1));
                    }

                } else if (model.typeof[prop] && model.typeof[prop].prototype instanceof Model) {
                    payload[prop] = this.toJsonPayload(model[prop], level + 1);

                } else if (model.typeof[prop] instanceof Enum){
                    payload[prop] = parseInt(model.valueof[prop]);
                    
                } else {
                    payload[prop] = model[prop];
                }
            }
            return payload;
        },



        beginUpdateSavedLocation(){
            const savedLocation = this.savedLocations[this.serviceRequest.serviceLocationId];
            this.updateLocationDTO = new ServiceLocationCreateDTO({
                id : this.serviceRequest.serviceLocationId,
                name: savedLocation.name,
                street : savedLocation.street,
                city : savedLocation.city,
                state: savedLocation.state,
                zipCode: savedLocation.zipCode
            });
            this.updateSavedLocation = true;
        },
        cancelUpdateSavedLocation(){
            this.updateSavedLocation = false;
            this.updateLocationDTO = null;
        },
        submitUpdateSavedLocation(){
            alert("!!Pretend this was saved");
            this.updateSavedLocation = false;
            this.updateLocationDTO = null;
        },


        submit(){
            //!!Validation check
            if (!this.stepValid[5]){
                alert("!!Something not valid");
                return false;
            }

            let payload = this.toJsonPayload(this.serviceRequest);
            console.log(payload);
            axiosInstance({
                method: 'post',
                url: 'ServiceRequest/Create',
                data: payload,
            }).then((response) => {
                //remember what we just added
                this.newRequestId = response.data;
                //show the overlay
                this.overlay = true;
                //get the new id list !!can be removed when we have pushed records
                this.$store.dispatch('getSeries', ServiceRequestModel);
                return response;
            }, (error)=> {
                alert("!!Something went wrong");
                for (var prop in error.errors){
                    if (prop.indexOf("$.") === 0){
                        let fixedProp = prop.substring(2);
                        if (Object.hasOwnProperty.call(this.serviceRequest, fixedProp)){
                            this.serviceRequest._error[fixedProp] = error.errors[prop];
                        }
                    }
                }
                console.log(error);
                throw error;
            });
        },
        reset(){
            this.hasReviewed = false;
            this.currentStep = 1;
            this.serviceRequest = new ServiceRequestCreateDTO({
                equipment: [],
                startTime: new Date(),
                endTime: new Date(),
                isSecondaryContact: false,
                _edit: true,
            });

            this.holdForeignTrailer = new EquipmentCreateDTO({
                ownerAccountId: this.$store.state.UserProfile.clientAccount
            });

            this.updateLocationDTO = null;

            this.holdTrailerLocationType = null;
            this.holdEquipmentIssues = {};
            this.holdEquipmentIssueDescriptions = {};
            this.holdEquipmentIssueImages = {};
            this.updateSavedLocation = false;

            //Equipment Selection
            this.searchTerm = '';
            this.pmFilter = null;
            this.overlay = false;
        }
    },
    computed: {
        trailerLocation(){
            if (this.holdTrailerLocationType === 0){
                return "GPS LOCATION";
            } 
            if (this.holdTrailerLocationType === 1){
                return "ENTERED ADDRESS";
            }
            if (this.holdTrailerLocationType === 2){
                return "SAVED ADDRESS";
            }
            return null;
        },
        multipleEquipment(){
            return Object.keys(this.equipmentSelections).length > 1;
        },
        //firstSelectedEquipmentId
        fSEI(){
            return Object.keys(this.equipmentSelections)[0] || null;
        },

        stepValid(){
            let validation = {};
            if (!this.loaded){
                return {};
            }
            const SR = this.serviceRequest;
            for (var step in this.steps){
                step = parseInt(step);
                validation[step] = false;

                //console.log(!Object.entries(SR._error).some(x => x[1] !== null));

                //any trailers are selected
                if (step === 1){
                    if (Object.keys(this.equipmentSelections).length){
                        validation[step] = true;
                        continue;
                    } else {
                        return validation;
                    }
                }
                if (step === 2){
                    const validLocation = 
                        //saved location type, selected, and not updating
                        this.holdTrailerLocationType === 2 
                        && this.updateSavedLocation === false
                        && SR.serviceLocationId
                        
                        //entering new service location, street address is valid
                        || this.holdTrailerLocationType === 1
                        && SR.newServiceLocation
                        && SR.newServiceLocation.street && !SR.newServiceLocation._error.street
                        && SR.newServiceLocation.city && !SR.newServiceLocation._error.city
                        && SR.newServiceLocation.state && !SR.newServiceLocation._error.state
                        && SR.newServiceLocation.zipCode && !SR.newServiceLocation._error.zipCode
                        && (
                            //if saving, must have name
                            SR.newServiceLocation.saved !== true || 
                            SR.newServiceLocation.name 
                        )

                        //map point select or GPS, with valid lat/long
                        || this.holdTrailerLocationType === 3 || this.holdTrailerLocationType === 0
                        && SR.newServiceLocation.latitude && !SR.newServiceLocation._error.latitude
                        && SR.newServiceLocation.longitude && !SR.newServiceLocation._error.longitude;
                    if (
                        //shop, shop selected, not towing or towing with valid location
                        SR.valueof.serviceLocationType === 1
                        && SR.shopLocationId !== null
                        && (
                            SR.requestTowing === false
                            || SR.requestTowing === true && validLocation
                        )
                        
                        //on site with valid location
                        || SR.valueof.serviceLocationType === 2
                        && validLocation
                    ){
                        if (SR.valueof.serviceLocationType === 2 && SR.requestTowing !== false){
                            console.log("SETTING FALSE");
                            SR.requestTowing = false;
                        }
                        if (this.multipleEquipment){
                            SR.urgentRequest = false;
                        }
                        validation[step] = true;
                        continue;
                    } else {
                        return validation;
                    }
                }
                if (step === 3){
                    if (
                        SR.urgentRequest
                        || SR.urgentRequest === false  
                            && SR.startTime 
                            && SR.endTime
                    ){
                        validation[step] = true;
                        continue;
                    } else {
                        return validation;
                    }
                }
                if (step === 4){
                    if (
                        //there are request details
                        SR.serviceRequestEquipmentDetails.length 
                        //and all of them have answers to all required bools
                        && !Object.values(SR.serviceRequestEquipmentDetails).some((x) => {
                            return x.loaded === null 
                                || x.hazardous === null
                                || x.forceEstimate === null
                                || x.driverPresent === null
                        })
                        //all equipments have their issues selected
                        && !Object.values(this.holdEquipmentIssues).some(x => x.length === 0)
                    ){
                        validation[step] = true;
                        continue;
                    } else {
                        return validation;
                    }
                }
                if (step === 5){
                    if (
                        SR.primaryContactId
                        || SR.primaryContactName
                            && (SR.primaryContactEmail || SR.primaryContactPhone)
                            && (SR.primaryPreferredContactMethod)
                    )
                    validation[step] = true;
                    continue;
                }
            }
            return validation;

        },


    }
}
</script>

<style lang="scss">
@import '../assets/scss/variables';
$journey-height: 800px + $space-wide * 2;

.service-request-flow {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;

    .split-list{
        padding: 0;

        .form-group{
            padding: 0;
            margin: 0;
            list-style-type: none;
        }
    }

    .card-search{
        padding-left: 0;

        .fa-search{
            left: 9px;
        }
    }

    .dual-button{
        margin-bottom: $space-wide;
    }

    .step-subsection{
        padding-bottom: $space-wide;
    }

    .extra-space{
        padding-top: $space-wide;
    }

    h4{
        padding: $space-narrow 0 $space-wide;
    }

    .window-wrapper{
        >div{
            margin: auto 0;
            height: 100%;

            @media (min-height: $journey-height + 68px + 57px){
                min-height: $journey-height - 57px - $space-wide * 2 + 68px;
                height: auto;
            }
        }
    }
}

.journey{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: $journey-height;
    max-height: 100%;
    width: 300px;
    margin: auto $space-wide;
    padding: $space-wide 0;

    > h2{
        flex: 0;
        padding-left: $space-wide + 2;
        color: nth($grays, 4);
        margin-bottom: $space-narrow;
    }
    
    .step{
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 $space-wide;
        border-radius: $input-radius * 2;
        border: 2px solid nth($grays, 10);
        color: nth($grays, 5);
        margin: auto 0;
        margin-bottom: $space-standard;
        transition: $transition-standard;
        background: rgba(0,0,0,0.05);
        
        >div {
            min-height: 76px;
        }
        
        h3{
            font-weight: $font-regular;
        }

        &.ready{
            background: rgba(255,255,255,1);
            cursor: pointer;
            color: nth($grays, 1);
        }
        &.active, &.ready:hover{
            // border-color: nth($brands, 10);
            color: nth($grays, 1);
            background: nth($brands, 10);
        }
    }
}




.equipment-selection-step{
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    > div{
        width: calc(50% - #{$space-wide});
    }
    .equipment-selection-step-wrap{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-height: calc(100vh - #{57px + $space-wide * 2 + 68px});
        max-height: $journey-height - $space-wide * 2;
        padding-bottom: 20px;
        
    }

    .card-search{
        flex: 0 0 auto;
    }

    .equipment-cards{
        padding-left: 0;
        overflow-y: scroll;
        height: auto;
        
        @include custom-scroll;

        .card-content{
            padding: 0;
        }
        img{
            height: 20px;
        }
        .service-requests:first-letter {
            text-transform: uppercase;
        }
    }

    .foreign-unit-prompt{
        text-align: center;
        margin: $space-wide 0 0;
        border-top: 1px solid nth($grays,9);
        padding: $space-wide 0 0;
    }

    .equipment-selected{
        ul{
            padding: 0;
        }
        li{
            list-style: none;
            font-size: 16px;
            line-height: 24px;
            font-weight: $font-bold;
            padding: $space-standard;
            border-bottom: 1px solid nth($grays, 9);
            cursor: pointer;
            i{
                line-height: 24px;
                float: right;
            }

            &:hover{
                background: nth($brands, 10);

                i {
                    color: $red;
                }
            }
        }
    }


}




.service-location-step,
.service-schedule-step{
    .panel {
        >div{
            background: #FFFFFF;
            border-left: 5px solid $brand;
            padding-left: $space-wide;
            margin-bottom: $space-wide * 2;
        }
    }
}

.split-service-selector{
    + .split-service-selector{
        border-top: 1px solid nth($grays, 9);
        padding: $space-wide * 2 0;
    }
}

.split-group{
    list-style-type: none;
    padding: 0;
    columns: 2;
}


.equipment-issues-step{

    .radio-line{
        width: 480px;

        >span{
            flex: 1 1 auto
        }
    }
    
    .split-service-selector {
        padding-bottom: $space-wide * 2;
        
        > div {
            background: #FFFFFF;
            border-left: 5px solid $brand;
            padding-left: $space-wide;
            width: 100%;
        }
    }
}
.service-review-step{
    h2, h4 {
        
        i{
            font-size: 16px;
            line-height: 24px;
            vertical-align: middle;
            margin-top: -2px;
            cursor: pointer;
            color: $green;
        }
    }
    .headless-table{
        width: 100%;
        margin-bottom: $space-wide;

        td:first-child{
            width: 300px;
            max-width: 40%;
            vertical-align: top;

            strong{
                color: $font-color;
            }
        }
    }
    .split {
        .headless-table{
            td:first-child{
                width: auto;
                max-width: 180px;
            }
        }
    }
}

.split{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    > div{
        width: calc(50% - #{$space-standard});
        flex: 0 0 auto;
    }
}


@media (max-width: $break-mobile){
    .journey{
        display: none;
    }
}


</style>