import Model from './Model.js';


class ServiceLocationCreateDTO extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('name', String, true);
        this.prop('street', String, true);
        this.prop('street2', String, true);
        this.prop('city', String, true);
        this.prop('state', String, true);
        this.prop('zipCode', String, true, {"maxLength":5});
        this.prop('latitude', Number, true);
        this.prop('longitude', Number, true);
        this.prop('description', String, true);
        this.prop('owner', Number, true);
        this.prop('saved', Boolean, false);

        this.populate(values, config);

    }
    static name = 'ServiceLocationCreateDTO';
}
window[Symbol.for(ServiceLocationCreateDTO.name)] = ServiceLocationCreateDTO;

export default ServiceLocationCreateDTO;