import Model from './Model.js';

import MakeModel from './MakeModel.js';
import DoorType from './DoorType.js';
import SuspensionType from './SuspensionType.js';
import WallType from './WallType.js';

class EquipmentCreateDTO extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', String, true);
        this.prop('make', MakeModel, true);
        this.prop('trailerNumber', String, true);
        this.prop('year', Number, true);
        this.prop('nominalLength', Number, true);
        this.prop('description', String, true);
        this.prop('vin', String, true, {"length":17});
        this.prop('doorType', DoorType, true);
        this.prop('suspensionType', SuspensionType, true);
        this.prop('wallType', WallType, true);
        this.prop('gpsEquipped', Boolean, false);
        this.prop('registrationState', String, true);
        this.prop('plateNumber', String, true);
        this.prop('ownerAccountId', Number, true);
        this.prop('pmInspectionInterval', Number, true);
        this.prop('lastPMDate', Date, true);
        this.prop('lessor', String, false);

        this.populate(values, config);

    }
    static name = 'EquipmentCreateDTO';
}
window[Symbol.for(EquipmentCreateDTO.name)] = EquipmentCreateDTO;

export default EquipmentCreateDTO;