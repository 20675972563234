<template>
    <div class="date-time-picker">
        <!-- <input type="datetime-local" class="unshown" :value="value" :name="name" /> -->
        <div class="date">
            <select class="month-select" :value="holdMonth" @change="setUTCMonth(parseInt($event.target.value))">
                <option v-for="month, i in months" :key="i" :value="i">{{months[i]}}</option>
            </select>
            <div>/</div>
            <select @keydown="arrowDate" class="day-select" :value="holdTimeDate" @change="setUTCDate(parseInt($event.target.value))">   
                <option v-for="day in daysThisMonth" :key="day.value" :value="day.value">{{day.display}}</option>
            </select>
            <div>/</div>
            <select class="year-select" :value="holdYear" @change="setYear(parseInt($event.target.value))">   
                <option v-for="i, year in (holdMaxYear - holdMinYear)" :key="holdMinYear + year" :value="holdMinYear + year">{{holdMinYear + year}}</option>
            </select>
            <div class="calendar" :class="{'no-ss': !ss}">
                <div class="month-year">
                    <div class="calendar-months slide-wrapper" @wheel.prevent="wheelMonths">
                        <div>
                            <div class="months-wrap" :style="{'top': 0 - (holdMonth *38) + 'px'}">
                                <a v-for="month, i in months" :key="month" :class="{'active' : holdMonth === i, 'disabled' : i < holdMinMonth || i > holdMaxMonth}" @click.prevent="setUTCMonth(i)">{{month}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="calendar-year slide-wrapper" @wheel.prevent="wheelYears">
                        <div>
                            <div>
                                <a v-for="year, i in 5" :key="holdYear - 2 + i" @click.prevent="setYear(holdYear - 2 + i)" :class="{'active': i === 2, 'disabled': (holdYear - 2 + i < holdMinYear) || (holdYear - 2 + i > holdMaxYear)}">{{holdYear - 2 + i}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="days" @wheel.prevent="wheelWeeks">
                    <div class="weeks week-header">
                        <div class="day" v-if="ss">S</div>
                        <div class="day">M</div>
                        <div class="day">T</div>
                        <div class="day">W</div>
                        <div class="day">T</div>
                        <div class="day">F</div>
                        <div class="day" v-if="ss">S</div>
                    </div>
                    <div class="weeks">
                        <transition-group name="cross-fade">
                            <div v-for="day in days" :key="day.value" class="day" @click.prevent="setUTCDate(day.value)" :class="{'active': holdDate === day.display, 'disabled' : day.value < holdMinDate || day.value > holdMaxDate, 'monthly': holdMonth === day.month}">
                                {{day.display}}
                            </div>
                        </transition-group>
                    </div>
                </div>
            </div>

        </div>
        <div class="time">
            <select :value="holdHour" @change="setHour(parseInt($event.target.value))" class="hour-select">
                <option v-for="h in hours" :key="h.value" :value="h.value" :class="{'active': h.value === holdHour, 'disabled': h.value < holdMinHour || h.value > holdMaxHour}">{{h.display > 9 ? h.display : `&nbsp;${h.display}`}}</option>
            </select>
            <div class="mobile-sep">:</div>
            <select :value="holdMinute" @change="setMinute(parseInt($event.target.value))" class="minute-select">
                <option v-for="m in minutes" :key="m" :value="m">{{m > 9 ? m : `0${m}`}}</option>
            </select>
            <div class="mobile-sep">:</div>
            <select :value="holdHour < 12 ? 'AM' : 'PM' " @change="changeAmPm" class="am-pm-select">
                <option v-for="m in ['AM', 'PM']" :key="m" :value="m">{{m}}</option>
            </select>
            <div class="time-wrapper">
                <div class="time-slider hours" @wheel.prevent="wheelHours">
                    <div>
                        <div :style="{'top': 0 - (offsetUTCHours * 38) + 'px'}">
                            <a v-for="h in hours" :key="h.value" @click.prevent="setHour(h.value)" :class="{'active': h.value === holdHour, 'disabled': h.value < holdMinHour || h.value > holdMaxHour}">{{h.display > 9 ? h.display : `&nbsp;${h.display}`}}</a>
                        </div>
                    </div>
                </div>
                <div class="sep">:</div>
                <div class="time-slider minutes" @wheel.prevent="wheelMinutes">
                    <div>
                        <div :style="{'top': 0 - (offsetUTCMinutes * 38) + 'px'}">
                            <a v-for="m in minutes" :key="m" @click.prevent="setMinute(m)" :class="{'active': holdMinute === m, 'disabled': m < holdMinMinute || m > holdMaxMinute}">{{m > 9 ? m : `0${m}`}}</a>
                        </div>
                    </div>
                </div>
                <div class="sep">:</div>
                <div class="time-slider" @wheel.prevent="wheelAmPm">
                    <div>
                        <div class="am-pm" :style="{'top': holdHour < 12 ? '0px' : '-38px'}">
                            <a @click.prevent="changeAmPm" :class="{'active' : holdHour < 12, 'disabled': holdMinHour > 11}">am</a>
                            <a @click.prevent="changeAmPm" :class="{'active' : holdHour > 11, 'disabled': holdMaxHour < 12}">pm</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        'name': String,

        'modelValue': {
            type: Date,
            required: true
        },

        'dateOnly': Boolean,
        'timeOnly': Boolean,

        'minDate': Date,
        'maxDate': Date,

        'step': {
            type: Number,
            default: 15,
        },
        'minTimeHour': {
            type: Number,
            default: 0,    
        },
        'maxTimeHour': {
            type: Number,
            default: 23,   
        },
        'minTimeMinute': {
            type: Number,
            default: 0,
        },
        'maxTimeMinute': {
            type: Number,
            default: 0,
        },
        'ss': {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            holdTime: 0 
        }
    },
    methods: {
        commit(){
            let date = new Date(this.holdTime);
            this.$emit('update:modelValue', date);
        },

        setYear(i){
            let date = new Date(this.holdTime);
            date.setUTCFullYear(i);
            this.holdTime = date.getTime();
        },
        setUTCMonth(i){
            let date = new Date(this.holdTime);
            date.setUTCMonth(i);
            this.holdTime = date.getTime();
        },
        setUTCDate(i){
            let date = new Date(i);
            date.setUTCHours(this.holdHour);
            date.setUTCMinutes(this.holdMinute);
            this.holdTime = date.getTime();
        },
        setHour(i){
            let date = new Date(this.holdTime);
            date.setUTCHours(i);
            this.holdTime = date.getTime();
        },
        setMinute(i){
            let date = new Date(this.holdTime);
            date.setUTCMinutes(i);
            this.holdTime = date.getTime();
        },

        wheelYears(event){
            if (event.deltaY < 0 && this.holdYear > this.holdMinYear){
                this.setYear(this.holdYear - 1);
            } else if (event.deltaY > 0 && this.holdYear < this.holdMaxYear){
                this.setYear(this.holdYear + 1);
            }
        },
        wheelMonths(event){
            if (event.deltaY < 0 && this.holdMonth > this.holdMinMonth){
                this.setUTCMonth(this.holdMonth - 1);
            } else if (event.deltaY > 0 && this.holdMonth < this.holdMaxMonth){
                this.setUTCMonth(this.holdMonth + 1);
            }
        },
        wheelWeeks(event){
            if (event.deltaY < 0 && this.holdTime - 7 * 24 * 60 * 60 * 1000 >= this.holdMinDate){
                this.setUTCDate(this.holdTime - 7 * 24 * 60 * 60 * 1000);
            } else if (event.deltaY > 0 && this.holdTime + 7 * 24 * 60 * 60 * 1000 < this.holdMaxDate){
                this.setUTCDate(this.holdTime + 7 * 24 * 60 * 60 * 1000);
            }
        },
        arrowDate(event){
            if (event.keyCode === 38 && this.holdTime - 7 * 24 * 60 * 60 * 1000 >= this.holdMinDate){
                //up
                this.setUTCDate(this.holdTime - 7 * 24 * 60 * 60 * 1000);
                event.stopPropagation();
                event.preventDefault();
            } else if (event.keyCode === 40 && this.holdTime + 7 * 24 * 60 * 60 * 1000 < this.holdMaxDate){
                //down
                this.setUTCDate(this.holdTime + 7 * 24 * 60 * 60 * 1000);
                event.stopPropagation();
                event.preventDefault();
            } else if (event.keyCode === 37 && this.holdTime - 24 * 60 * 60 * 1000 >= this.holdMinDate){
                //left
                this.setUTCDate(this.holdTime - 24 * 60 * 60 * 1000);
                event.stopPropagation();
                event.preventDefault();
            } else if (event.keyCode === 39 && this.holdTime + 24 * 60 * 60 * 1000 >= this.holdMinDate){
                //right
                this.setUTCDate(this.holdTime + 24 * 60 * 60 * 1000);
                event.stopPropagation();
                event.preventDefault();
            }
        },
        wheelHours(event){
            if (event.deltaY < 0 && this.holdHour > this.holdMinHour){
                this.setHour(this.holdHour - 1);
            } else if (event.deltaY > 0 && this.holdHour < this.holdMaxHour){
                this.setHour(this.holdHour + 1);
            }
        },
        wheelMinutes(event){
            if (event.deltaY < 0 && this.holdMinute - this.step >= 0){
                this.setMinute(this.holdMinute - this.step);
            } else if (event.deltaY > 0 && this.holdMinute + this.step < 60){
                this.setMinute(this.holdMinute + this.step);
            }
        },
        
        changeAmPm(){
            if (this.holdHour > 11 && this.holdHour - 12 >= this.holdMinHour){
                this.holdTime -= 12 * 60 * 60 * 1000;
            } else if (this.holdHour + 12 <= this.holdMaxHour){
                this.holdTime += 12 * 60 * 60 * 1000;
            }
        },
        wheelAmPm(event){
            if (event.deltaY < 0 && this.holdHour > 11){
                this.changeAmPm();
            } else if (event.deltaY > 0 && this.holdHour < 12){
                this.changeAmPm();
            }
        },

        dateAtMidnight(t){
            return Math.floor(t / (24 * 60 * 60 * 1000)) * (24 * 60 * 60 * 1000); 
        },
        tzo(){
            return this.modelValue.getTimezoneOffset() * 60 * 1000;
        },
    },
    computed: {

        holdYear(){
            return new Date(this.holdTime).getUTCFullYear();
        },
        holdMonth(){
            return new Date(this.holdTime).getUTCMonth();
        },
        holdDate(){
            return new Date(this.holdTime).getUTCDate();
        },
        holdTimeDate(){
            return this.dateAtMidnight(this.holdTime);
        },
        holdHour(){
            return new Date(this.holdTime).getUTCHours();
        },
        holdMinute(){
            return new Date(this.holdTime).getUTCMinutes();
        },

        holdMinYear(){
            if (this.minDate){
                return this.minDate.getUTCFullYear();
            }
            return 1900;
        },
        holdMinMonth(){
            if (this.minDate && this.holdYear <= this.holdMinYear){
                return this.minDate.getUTCMonth();
            }
            return 0;
        },
        holdMinDate(){
            if (this.minDate && this.holdYear <= this.holdMinYear && this.holdMonth <= this.holdMinMonth){
                return this.dateAtMidnight(this.minDate.getTime());
            }
            return -3000000000000;
        },
        holdMinHour(){
            if (this.minDate && this.holdYear <= this.holdMinYear && this.holdMonth <= this.holdMinMonth && this.holdTimeDate <= this.holdMinDate){
                return this.minDate.getUTCHours();
            }
            return 0;
        },
        holdMinMinute(){
            if (this.minDate && this.holdYear <= this.holdMinYear && this.holdMonth <= this.holdMinMonth && this.holdTimeDate <= this.holdMinDate && this.holdHour <= this.holdMinHour){
                return Math.floor(this.minDate.getUTCMinutes() / this.step) * this.step;
            }
            return 0;
        },

        holdMaxYear(){
            if (this.maxDate){
                return this.maxDate.getUTCFullYear();
            }
            return 2500;
        },
        holdMaxMonth(){
            if (this.maxDate && this.holdYear >= this.holdMaxYear){
                return this.maxDate.getUTCMonth();
            }
            return 11;
        },
        holdMaxDate(){
            if (this.maxDate && this.holdYear >= this.holdMaxYear && this.holdMonth >= this.holdMaxMonth){
                return this.dateAtMidnight(this.maxDate.getTime());
            }
            return 3000000000000;
        },
        holdMaxHour(){
            if (this.maxDate && this.holdYear >= this.holdMaxYear && this.holdMonth >= this.holdMaxMonth && this.holdTimeDate >= this.holdMaxDate){
                return this.maxDate.getUTCHours();
            }
            return 23;
        },
        holdMaxMinute(){
            if (this.maxDate && this.holdYear >= this.holdMaxYear && this.holdMonth >= this.holdMaxMonth && this.holdTimeDate >= this.holdMaxDate && this.holdHour >= this.holdMaxHour){
                return Math.floor(this.minDate.getUTCMinutes() / this.step) * this.step;
            }
            return 60;
        },



        hours(){
            return Array.from({length: this.maxTimeHour - this.minTimeHour + 1}, (l, i) => {
                let v = this.minTimeHour + i; 
                return {
                    value: v, 
                    display: v === 0 ? 12 : v > 12 ? v - 12 : v,
                };
            });
        },
        minutes(){
            return Array.from({length: 60 / this.step}, (l, i) => {
                return i * this.step;
            });
        },
        offsetUTCHours(){
            return Object.values(this.hours).findIndex(x => x.value === this.holdHour);
            // return 0;
        },
        offsetUTCMinutes(){
            return this.minutes.indexOf(this.holdMinute);
        },
        days(){
            let day = new Date(this.holdTime).getUTCDay();
            let days = Array.from({length: 28}, (x, i) => {
                let date = new Date(this.dateAtMidnight(this.holdTime + (i - day - 7) * 24 * 60 * 60 * 1000));
                if (this.ss || [0,6].indexOf(date.getUTCDay()) === -1){
                    return {
                        display: date.getUTCDate(), 
                        value: date.getTime(),
                        month: date.getUTCMonth(),
                    }
                } else {
                    return false;
                }
            });
            return days.filter(x => x !== false);
        },
        daysThisMonth(){
            return this.days.filter(x => x.month === this.holdMonth);
        }
    },
    watch: {
        holdTime(){
            //!!Literally all the min/max checking
            let date = new Date(this.holdTime);
            if (date.getUTCFullYear() <= this.holdMinYear){
                date.setUTCFullYear(this.holdMinYear);

                if (date.getUTCMonth() <= this.holdMinMonth){
                    date.setUTCMonth(this.holdMinMonth);

                    if (this.dateAtMidnight(date.getTime()) <= this.holdMinDate){
                        date.setUTCDate(new Date(this.holdMinDate).getUTCDate());

                        if (date.getUTCHours() <= this.holdMinHour){
                            date.setUTCHours(this.holdMinHour);

                            if (date.getUTCMinutes() <= this.holdMinMinute){
                                date.setUTCMinutes(this.holdMinMinute);
                            }
                        }
                    }
                }
                this.holdTime = date.getTime();
            }
            this.commit();
        },
        minDate(){
            if (this.holdTime < this.minDate.getTime()){
                this.holdTime = this.minDate.getTime();
            }
        },
    },
    created (){
        let date = new Date();
        date.setUTCMinutes(Math.floor(this.modelValue.getUTCMinutes() / this.step) * this.step);
        this.holdTime = date.getTime();
    }
}
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";
$si: 8px;
$shad: 0 3px 12px rgba(0,0,0,0.25);

.date-time-picker
{
    display: flex;
    text-align: center;
    @include noselect;

    .unshown{
        position: absolute;
        height: 1px;
        width: 1px;
        opacity: 0;
    }

    $cw: 36px;
    $ch: 38px;
    .date{
        height: $ch + 2;
        border: 1px solid nth($grays, 7);
        border-radius: $input-radius;
        margin-right: $space-standard;
        position: relative;
        display: flex;
        justify-content: space-around;
        font-size: 20px;
        line-height: $ch;
        background: rgba(255,255,255,1);
        min-width: $cw * 7;

        >select{
            border: none;
            height: $ch;
            font-size: 20px;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            text-align: center;

            &.month-select{
                width: 50%;
            }
            &.day-select{
                width: 20%;
            }
            &.year-select{
                width: 30%;
            }
        }
    }
    .date>select:focus ~ .calendar,
    .date:hover .calendar{
        height: $ch * 6;
        box-shadow: $shad;
        z-index: 51;
        overflow: visible;
        animation: 0.16s delay-overflow;
        transition: opacity 0.15s ease-out, height 0s; 
        opacity: 1;
    }
    .date>select.year-select:focus ~ .calendar .calendar-year,
    .date>select.month-select:focus ~ .calendar .calendar-months,
    .date>select.day-select:focus ~ .calendar .days .active{
        box-shadow: 0 0 0 1px inset $green;
    }


    .month-year{
        display: flex;
        justify-content: stretch;
        height: $ch;
        overflow: visible;

        >div{
            flex: 1 1 auto;
        }
    }


    .calendar{
        position: absolute;
        height: 0;
        top: 0 - $ch;
        left: -1px;
        opacity: 0;
        background: rgba(255,255,255,1);
        border-radius: $input-radius;
        z-index: 20;
        width: calc(100% + 2px);
        overflow: hidden;
        transition: opacity 0.15s ease-out, height 0s 0.15s;

        > div {
            position: relative;
            transition: $transition-standard;
        }

        .weeks{

            .day{
                display: block;
                float: left;
                width: calc(100% / 7);
                text-align: center;
                cursor: pointer;
                box-shadow: 1px 1px 0 0 inset nth($grays, 10);
                background: rgba(nth($grays,10), 0.5);

                &.monthly{
                    background: #FFFFFF;
                }
                &.active{
                    background: nth($brands, 10);
                }
                &.disabled{
                    background: nth($grays, 10);
                    color: nth($grays, 8);
                }
            }
        }
        .week-header{
            border-bottom: 1px solid nth($grays, 7);
            .day{
                background: nth($grays, 10);
                line-height: 37px;
            }
        }
        &.no-ss{
            left: 0 - $cw * 2.5;
            .weeks{
                width: $cw * 5;
            }
        }
    }





    .calendar-year{
        >div{
            >div{
                margin-top: 0 - 38px * 2;
                a {
                    padding: 0 $space-standard;
                }
            }
        }
    }



    .time{
        font-size: 20px;
        line-height: 40px;
        height: 40px;
        text-align: center;
        border: 1px solid nth($grays, 7);
        border-radius: $input-radius;
        background: #FFFFFF;

        .time-wrapper{
            display: flex;
            position: relative;
            overflow: hidden;
            height: $ch;
            margin: 0 -1px; 
            top: 0;
            transition: $transition-standard;
            background: rgba(255,255,255,0);
            border-radius: $input-radius;
            z-index: 20;
            .sep{
                height: $ch;
                margin-top: 0px;
            }

            >div{
                transition: $transition-standard;

                a:not(.active){
                    opacity: 0;
                }
            }
        }

    }
    .time:hover .time-wrapper,
    .time > select:focus ~ .time-wrapper{
        height: 38px * 5;
        top: -38px * 2;
        box-shadow: $shad;
        background: rgba(255,255,255,1);
        z-index: 51;
        
        .sep{
            margin-top: 38px * 2;
            background: nth($brands, 10);
        }

        > div {
            >div{    
                margin-top: 38px * 2;
            }

            a {
                opacity: 1;
            }

        }

        .active{
            background: nth($brands, 10);
        }
    }

    .slide-wrapper{
        overflow: hidden;
        height: $ch;
        top: 0;
        background: rgba(255,255,255,1);
        border-radius: $input-radius;
        &:hover {
            height: 38px * 5;
            top: -38px * 2;
            box-shadow: $shad;
            background: rgba(255,255,255,1);
            z-index: 51;

            .active{
                background: nth($brands, 10);
            }


            > div {
                margin-top: 38px * 2;
            }
        }
    }

    .slide-wrapper, 
    .time-slider{
        position: relative;
        transition: $transition-standard;
        z-index: 20;

        > div {
            margin-top: 0px;
            transition: $transition-standard;
            width: 100%;

            > div{
                position: relative;
                transition: $transition-standard;
            }
        }
        
        a{
            display: block;
            color: $font-color;
            height: 38px;
            text-decoration: none;
            min-width: $cw;
            width: 100%;

            &.disabled{
                background: nth($grays, 10);
                color: nth($grays, 8);
            }
        }

    }
    .hours, .minutes{
        overflow: visible;
        position: relative;

        >div{
            position: absolute;
        }
    }


    .am-pm{
        text-transform: uppercase;
        width: calc(2em + #{$space-standard});
        a{
            padding-right: $space-standard;
        }
    }
    .minutes{
        width: $cw;
    }
    .hours{
        width: $cw + $space-standard;
        a {
            padding-left: $space-standard;
        }
    }

    .mobile-sep{
        display: none;
    }
    .hour-select, 
    .minute-select,
    .am-pm-select{
        position: absolute;
        top:-10000px;
        left: -100000px;
        height: 0;
        width: 0;
    }
    .hour-select:focus ~ .time-wrapper .hours .active,
    .minute-select:focus ~ .time-wrapper .minutes .active,
    .am-pm-select:focus ~ .time-wrapper .am-pm .active{
        box-shadow: 0 0 0 1px inset $green;
        border-radius: $input-radius;
    }
}


@media (max-width: $break-mobile){
.date-time-picker{
    .time {
        display: flex;
        flex-wrap: none;

        .time-wrapper{
            display: none;
        }
    }


    .mobile-sep{
        display: block;
        line-height: 38px;
    }
    .hour-select, 
    .minute-select,
    .am-pm-select{
        position: static;
        top: 0;
        left: 0;
        height:38px;
        width: auto;
        display: block;
        border: none;
        background: none;
        font-size: 20px;
        line-height: 38px;
        width: 40px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        padding: 0;
        text-align: center;
    }
}
}

</style>
